<template>
  <div class="page-content mb-1">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Pronun Test - ZH" class="mb-2" />
      </b-col>
    </b-row>
    <b-tabs content-class="pt-5" justified v-model="query.tab">
      <b-tab>
        <template #title>
          <feather-icon class="h4" icon="MicIcon" />
          <span class="h4">Test words</span>
        </template>

        <div>
          <div class="mt-5 d-flex justify-content-center">
            <b-button size="small" variant="primary" @click="exportResults">Export Results</b-button>
            <b-button size="small" class="ml-2" icon="clear" variant="danger" @click="clearResults">Clear Results</b-button>
          </div>
          <b-card class="mt-3">
            <div class="d-flex justify-content-center">
              <b-button variant="outline-success" type="line" @click="addSentece" >Add Words</b-button>
            </div>
            <span class="d-inline-block ml-auto" v-if="!sentences.length">no sentence</span>
            <sentence-record-card class="border p-1 my-1"
            v-for="(sentence, index) in sentences"
            :key="sentence._id"
            :sentence="sentence"
            @remove="removeSentence(index)"
            />
          </b-card>

        </div>

      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon class="h4" icon="ShareIcon" />
          <span class="h4">Update words</span>
        </template>
        <div>
          <b-textarea v-model="words" />
          <b-button class="mt-1" variant="gradient-primary"
            @click="updateWords"
          >Update Words</b-button>
          <p v-if="task_id"><span>Task ID: </span>{{ task_id }}</p>
        </div>
      </b-tab>

    </b-tabs>

  </div>
</template>

<script>
import useURLBind from '@/@core/comp-functions/useURLBind';
import XLSX from 'xlsx';
import api from '../service';

export default {
  components: {
    SentenceRecordCard: () => import('../components/SentenceRecordCardS2T.vue')
  },
  setup(_, ctx) {
    let { query } = useURLBind({ tab: null }, ctx);
    return { query };
  },
  data() {
    return {
      sentences: [],
      words: "",
      task_id: null,
    };
  },
  methods: {
		addSentece() {
			let sentence = {...this.defaultSentence};
			sentence._id = Date.now();
			this.sentences.unshift(sentence);
		},
		clearResults() {
			this.sentences = [];
		},
		removeSentence(index) {
			this.sentences.splice(index, 1);
		},
		async saveExportFile(sentences) {
			let wb = XLSX.utils.book_new();
			let ws = XLSX.utils.json_to_sheet(sentences, {header:["text","audio_url","total_score","result"], skipHeader:false});
			XLSX.utils.book_append_sheet(wb, ws, 'Results');
			XLSX.writeFile(wb, 'results.xlsx');
    },
		exportResults() {
			let sentences = this.sentences.filter(item => { return !!item.result })
				.map(item => { return item.result });
			sentences = sentences.map(item => {
				return {
					text: item.text,
					audio_url: item.audio_url,
					total_score: item.total_score.toFixed(2),
					result: item.result,
				}
			})
			for (let i = 0, sentencesLength = sentences.length; i < sentencesLength; i++) {
				let sentence = sentences[i];
				this.compareLetters(sentence);
			}

			this.saveExportFile(sentences);
		},
		compareLetters(sentence) {
			if(!sentence.result) { return };
			let letters = [];
			sentence.result.forEach(word => {
				letters = letters.concat(word.letters)
			});
			let compare_results = letters.map((letter, index) => {
				return {
					letter: letter.letter,
					phone: `/${letter.phones[0] ? letter.phones[0].phone_ipa : 'NAN'}/`,
					score: letter ? letter.score.toFixed(2) : 'NAN',
				}
			});
			compare_results = compare_results.map(letter => {
				return `"${letter.letter}-${letter.phone}": ${letter.score}`;
			})
			compare_results.forEach((result, index) => {
				sentence[`letter_${index}`] = result;
			})
			sentence.result = JSON.stringify(sentence.result);
    },
    async updateWords() {
      let data = new FormData();
			data.append("token", "gcxpHQmLeVwLWobE6apU1lgAg49YTMa0");
			data.append("text", this.words);
      let response = await api.updateSpeechToTextWords(data);
      if(response.data.status == 0) {
        this.words = "";
        this.task_id = response.data.task_id;
      }
    },
	},
};
</script>
